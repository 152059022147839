"use client";

import React, { useEffect } from "react";
import ErrorPage from "rivals/components/pages/error/ErrorPage";
import logger from "rivals/shared/utils/logger";

const PAGE_NAME = "global-error";

// TODO: https://griosf.atlassian.net/browse/RVLS-8613
export default function GlobalError({
  error
}: {
  error: Error & { digest?: string };
}): React.JSX.Element {
  useEffect(() => {
    logger({ message: error, page: PAGE_NAME, severity: "error" });
  }, [error]);

  return (
    <html lang="en">
      <head>
        <title>500 - Internal Server Error</title>
      </head>
      <body>
        <div
          className="you-shall-not-pass-global"
          style={{ display: "none" }}
        ></div>
        <ErrorPage statusCode={500} userError />
      </body>
    </html>
  );
}
